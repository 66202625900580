import { createRouter, createWebHistory } from 'vue-router';
const routes = [
    {
        path: '/',
        component: () => import('@/layout/PPNSettingsLayout.vue'),
        redirect: '/pengaturan/ppn',
        children: [
            {
                path: '/pengaturan/ppn',
                name: 'Exampleview',
                component: () => import('@/views/PPNSettingsView.vue'),
            },
        ],
    },
];
const router = createRouter({
    history: createWebHistory((import.meta.env || process.env).BASE_URL),
    routes,
});
export default router;
